import { hasWindow, putWithAuthDecorator } from '@services/http.service';
import {
    ADD_OPPORTUNITY_PAYMENT_SCHEDULE_URL,
    ADD_WALLET_AMOUNT_URL,
    CREATE_ADDRESS_INVESTOR_DOCUMENT_FROM_ADMIN_URL,
    CREATE_ADDRESS_INVESTOR_DOCUMENT_URL,
    CREATE_INVESTOR_BANK_DETAILS_URL,
    CREATE_INVESTOR_DOCUMENT_URL,
    CREATE_INVESTOR_URL,
    CREATE_INVESTOR_WALLET_URL,
    CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS,
    CREATE_PAN_INVESTOR_DOCUMENT_URL,
    CREATE_USER_PERMISSION_URL,
    DOWNLOAD_INVESTORS_FOR_OPPORTUNITY,
    EDIT_ADDRESS_DETAILS_URL,
    EDIT_BANK_DETAILS_URL,
    EDIT_INVESTOR_ADDRESS_DOCUMENT_URL,
    EDIT_PANCARD_DETAILS_URL,
    GET_CONFIGURATIONS_URL,
    GET_DROPPED_OFF_INVESTORS_URL,
    GET_EXISTING_INVESTOR_URL,
    GET_INVESTOR_DOCUMENT_URL,
    GET_INVESTOR_RELATIONSHIP_MANAGER_DETAILS,
    GET_INVESTOR_URL,
    GET_INVESTORS_FOR_KYC_URL,
    GET_INVESTORS_URL,
    GET_OPPORTUNITY_PAYMENT_SCHEDULE_URL,
    MARK_BANK_DETAILS_STATUS_URL,
    MARK_INVESTOR_DOCUMENT_STATUS_URL,
    UPDATE_BULK_OPPORTUNITY_PAYMENT_SCHEDULE_URL,
    UPDATE_INVESTOR_BANK_DETAILS_ADMIN_URL,
    UPDATE_INVESTOR_INVITATION_BANK_DETAILS_URL,
    UPDATE_MOBILE_URL,
    UPDATE_NEW_EMAIL_URL,
    UPDATE_OPPORTUNITY_PAYMENT_SCHEDULE_BY_PAYMENT_ID_URL,
    GET_INVESTOR_BANK_DETAILS_URL,
    GET_INVESTOR_DOCUMENTS,
    GET_INVESTOR_WALLET, GET_IS_FTI,
    BULK_UPLOAD_OPPORTUNITY_PAYMENT_SCHEDULE_FILE,
} from '@services/urls/investor';
import {
    CREATE_INVESTMENT_URL,
    GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL,
    GET_INVESTOR_INVESTMENTS_URL,
} from '@services/urls/investment';
import {
    CREATE_PAYMENT_URL,
    GET_PAYMENT_HISTORY_URL,
    GET_PAYMENT_URL,
    UPDATE_PAYMENT_URL,
} from '@services/urls/payment';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';

export const validate = (mobile) => {
    const url = GET_EXISTING_INVESTOR_URL(mobile);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url);
};

export const createInvestor = (data, ctx = null) => {
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(CREATE_INVESTOR_URL(), data);
    }
    return SsrHttpService.postWithAuth(CREATE_INVESTOR_URL(), data, ctx);
};

export const createInvestment = (
    promisedAmount,
    investorId,
    opportunityId,
    ctx = null
) => {
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(
            CREATE_INVESTMENT_URL(),
            { promisedAmount, investorId, opportunityId },
            ctx
        );
    }
    return SsrHttpService.postWithAuth(
        CREATE_INVESTMENT_URL(),
        { promisedAmount, investorId, opportunityId },
        ctx
    );
};

export const getInvestor = (id, ctx = null) => {
    const url = GET_INVESTOR_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getIsFTI = (id, ctx = null) => {
    const url = GET_IS_FTI(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateNewEmail = (data, ctx = null) => {
    const url = UPDATE_NEW_EMAIL_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createInvestorDocument = (data, ctx = null) => {
    const url = CREATE_INVESTOR_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createInvestorBankDetails = (data, ctx = null) => {
    const url = CREATE_INVESTOR_BANK_DETAILS_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

// Start from here
export const getInvestors = (
    page,
    limit,
    searchText,
    searchPanText,
    ctx = null
) => {
    const url = GET_INVESTORS_URL(page, limit, searchText, searchPanText);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestmentByInvestorOpportunity = (
    investorId,
    opportunityId,
    ctx = null
) => {
    const url = GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL(
        investorId,
        opportunityId
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const editPanCardDetails = (id, data, ctx = null) => {
    const url = EDIT_PANCARD_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const editBankDetails = (id, data, ctx = null) => {
    const url = EDIT_BANK_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const editAddressDetails = (id, data, ctx = null) => {
    const url = EDIT_ADDRESS_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const markInvestorDocumentStatus = (id, data, ctx = null) => {
    const url = MARK_INVESTOR_DOCUMENT_STATUS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const markBankDetailsStatus = (id, data, ctx = null) => {
    const url = MARK_BANK_DETAILS_STATUS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const updateInvestorBankDetailsAdmin = (id, data, ctx = null) => {
    const url = UPDATE_INVESTOR_BANK_DETAILS_ADMIN_URL(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const createPayment = (data, ctx = null) => {
    const url = CREATE_PAYMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};
export const updatePayment = (data, ctx = null) => {
    const url = UPDATE_PAYMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getPayment = (opportunityId, entityId, ctx = null) => {
    const url = GET_PAYMENT_URL(opportunityId, entityId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getPaymentHistory = (id, opportunityId, orderId, ctx = null) => {
    const url = GET_PAYMENT_HISTORY_URL(id, opportunityId, orderId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityPaymentScheduleDetails = (
    opportunityId,
    status = [],
    ctx = null
) => {
    const url = GET_OPPORTUNITY_PAYMENT_SCHEDULE_URL(opportunityId, status);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateOpportunityPaymentScheduleDetails = (
    paymentId,
    data,
    ctx = null
) => {
    const url =
        UPDATE_OPPORTUNITY_PAYMENT_SCHEDULE_BY_PAYMENT_ID_URL(paymentId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const addOpportunityPaymentScheduleDetails = (data, ctx = null) => {
    const url = ADD_OPPORTUNITY_PAYMENT_SCHEDULE_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const bulkUploadOpportunityPaymentScheduleFile = (data, ctx = null) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('fileName', data.fileName);
    formData.append('opportunityId', data.opportunityId);
    formData.append('outOfScheduleData', JSON.stringify(data.outOfScheduleData));
    const url = BULK_UPLOAD_OPPORTUNITY_PAYMENT_SCHEDULE_FILE();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, formData);
    }
    return SsrHttpService.postWithAuth(url, formData, ctx);
};


export const UpdateBulkOpportunityPaymentScheduleDetails = (
    data,
    opportunityId,
    ctx = null
) => {
    const url = UPDATE_BULK_OPPORTUNITY_PAYMENT_SCHEDULE_URL(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const editInvestorAddressDocument = (id, data, ctx = null) => {
    const url = EDIT_INVESTOR_ADDRESS_DOCUMENT_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getConfigurations = (
    configurationType,
    entityType,
    ctx = null
) => {
    const url = GET_CONFIGURATIONS_URL(configurationType, entityType);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorsForKyc = (
    page,
    limit,
    searchText,
    searchPanText,
    kycStatus,
    ctx = null
) => {
    const url = GET_INVESTORS_FOR_KYC_URL(
        page,
        limit,
        searchText,
        searchPanText,
        kycStatus
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestments = (id, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENTS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
export const createUserPermission = (data, ctx = null) => {
    const url = CREATE_USER_PERMISSION_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getDroppedOffInvestors = (
    opportunityId,
    page,
    limit,
    ctx = null
) => {
    const url = GET_DROPPED_OFF_INVESTORS_URL(opportunityId, page, limit);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const downloadInvestorsForOpportunity = (opportunityId, ctx = null) => {
    const url = DOWNLOAD_INVESTORS_FOR_OPPORTUNITY(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url);
    }
    return SsrHttpService.postWithAuth(url, ctx);
};

export const createInvestorProfileDetails = (data, ctx = null) => {
    const url = CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS(data.investorId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const updateInvestorProfileDetails = (data, ctx = null) => {
    const url = CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS(data.investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateInvitedInvestorBankDetailsWithAuthDecorator = (
    data,
    bankDetailsId,
    auth
) => {
    const url = UPDATE_INVESTOR_INVITATION_BANK_DETAILS_URL(bankDetailsId);
    return putWithAuthDecorator(url, data, auth);
};

export const updateMobile = (data, ctx = null) => {
    const url = UPDATE_MOBILE_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};
export const createPanInvestorDocument = (data, ctx = null) => {
    const url = CREATE_PAN_INVESTOR_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createAddressInvestorDocument = (data, ctx = null) => {
    const url = CREATE_ADDRESS_INVESTOR_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createAddressInvestorDocumentFromAdmin = (
    investorId,
    data,
    ctx = null
) => {
    const url = CREATE_ADDRESS_INVESTOR_DOCUMENT_FROM_ADMIN_URL(investorId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getInvestorDocument = (investorId, type, ctx = null) => {
    const url = GET_INVESTOR_DOCUMENT_URL(investorId, type);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createInvestorWallet = (data, ctx = null) => {
    const url = CREATE_INVESTOR_WALLET_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const addWalletAmount = (data, ctx = null) => {
    const url = ADD_WALLET_AMOUNT_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getInvestorRelationshipManagerDetails = (id, ctx = null) => {
    const url = GET_INVESTOR_RELATIONSHIP_MANAGER_DETAILS(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorBankDetails = (id, ctx = null) => {
    const url = GET_INVESTOR_BANK_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorDocuments = (id, ctx = null) => {
    const url = GET_INVESTOR_DOCUMENTS(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorWallet = (id, ctx = null) => {
    const url = GET_INVESTOR_WALLET(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};